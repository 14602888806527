import React, { useState, useEffect } from 'react'
import styles from './MarketingNavigation.css'
import { Link } from '@reach/router'
import { PlainButton, LinkButton, AnchorButton } from 'src/components/Button'
import Logo from 'src/icons/HaullyLogoBlack.svg'
import Hamburger from 'src/icons/Hamburger.svg'
import { welcomePath, tipsAndToolsPath, searchPath } from 'src/paths'
import { Menu, MenuList, MenuButton, MenuLink } from '@reach/menu-button' // docs: https://ui.reach.tech/menu-button
import XIcon from 'src/icons/X.svg'
import Flex from 'src/components/Flex'
import Box from 'src/components/Box'
import { useSessionStore } from 'src/hooks/useStores'
import pdfUrl from 'src/images/Anderson message to 3rd party carriers 8.5.20.pdf'
import { SIGN_UP_URL } from 'src/data/navLinks'

/**
 * @param {Object} props
 * @param {() => void} props.onRequestLogin
 */
const MarketingNavigation = ({ onRequestLogin }) => {
  const [isExpanded] = useState(false)
  const [navbarHeight, setNavbarHeight] = useState(62)
  const sessionStore = useSessionStore()
  const isLoggedIn = sessionStore.isLoggedIn

  /** @type {React.RefObject<HTMLDivElement>} */
  const navbar = React.createRef()

  useEffect(() => {
    document.body.classList.add(styles.fullSizeMenu)
    return () => document.body.classList.remove(styles.fullSizeMenu)
  }, [])

  useEffect(() => {
    if (navbar.current === null)
      throw new Error('invariant: expected navbar to not be null')
    setNavbarHeight(navbar.current.clientHeight)
  }, [navbar])

  useEffect(() => {
    isExpanded ? disableScroll() : enableScroll()
    return () => enableScroll()
  })

  return (
    <>
      <div style={{ height: navbarHeight }} />
      <div ref={navbar} className={styles.NavigationBar}>
        <Link to={welcomePath()} className={styles.Logo}>
          <Logo />
        </Link>

        <Flex alignItems="center">
          <Box mr={3}>
            {isLoggedIn ? (
              <LinkButton to={searchPath()}>Dashboard</LinkButton>
            ) : (
              <>
                <PlainButton
                  onClick={onRequestLogin}
                  className={styles.LoginButton}
                >
                  Log in
                </PlainButton>
                <AnchorButton className={styles.HeaderLink} href={SIGN_UP_URL}>
                  Sign Up
                </AnchorButton>
              </>
            )}
          </Box>
          <HamburgerDropdown />
        </Flex>
      </div>
    </>
  )
}

/**
 * doesn't work in mobile safari. oh well. disabling scrolling via
 * document.body.addEventListener('touchmove', e => { e.preventDefault() }, { passive: false })
 * prevents scrolling in the fixed nav menu
 */
const disableScroll = () =>
  document.body.classList.add(styles.DisableBodyScroll)

const enableScroll = () =>
  document.body.classList.remove(styles.DisableBodyScroll)

const HamburgerDropdown = () => (
  <Menu>
    {({ isOpen }) => (
      <>
        <MenuButton className={styles.MenuButton}>
          {isOpen ? (
            <XIcon className={styles.CloseButton} />
          ) : (
            <Hamburger className={styles.Hamburger} />
          )}
        </MenuButton>
        <MenuList className={styles.MenuList}>
          <MenuLink
            component="a"
            href={pdfUrl}
            className={styles.MenuLink}
            target={'_blank'}
          >
            Chairman's Letter
          </MenuLink>
          <MenuLink
            component="a"
            href={SIGN_UP_URL}
            className={styles.MenuLink}
          >
            Find Hauls
          </MenuLink>
          <MenuLink
            component="a"
            href={welcomePath() + '#DiscoverTheDifference'}
            className={styles.MenuLink}
          >
            Features
          </MenuLink>
          <MenuLink
            component="a"
            href={welcomePath() + '#HowItWorks'}
            className={styles.MenuLink}
          >
            How it Works
          </MenuLink>
          <MenuLink
            component="a"
            href={welcomePath() + '#ContactUs'}
            className={styles.MenuLink}
          >
            Contact
          </MenuLink>
          <MenuLink
            as={Link}
            to={tipsAndToolsPath()}
            className={styles.MenuLink}
          >
            Resources &amp; FAQs
          </MenuLink>
        </MenuList>
      </>
    )}
  </Menu>
)

export default MarketingNavigation
